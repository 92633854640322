angular.module('app.controllers').controller 'LoginCtrl', [
  '$scope', '$rootScope', 'APICheckin', '$timeout', '$cookies', 'smoothScr', 'UI'
].append (self, root, APICheckin, $timeout, $cookies, smoothScr, UI) ->

  self.formProccess = false

  # errorType
  # 1 - wrong email 'Access denied'
  # 2 - wrong pass 'Password incorrect'
  # 3 - not confirmed 'E-Mail not confirmed'
  self.errorType = null

  self.resetError = () ->
    return unless self.errorType?
    self.errorType = null

  self.saveForm = (form) ->
    self.lForm = form

  self.data = 
    email: ''
    password: ''

  self.passType = true
  self.togglePass = () ->
    self.passType = !self.passType

  self.fake = 
    check: false

  self.login = (redirect = true) ->
    return if root.user?.auth_ticket
    self.errorType = null
    self.formProccess = true

    log = angular.copy(self.data)
    if log.email in ['sm@sm']
      log.email = 'rreyes4211@tconnectionsinc.com'
      log.password = '1234qweRt'

    (new APICheckin log).$save {action: 'checkin'}, (data) ->

      self.formProccess = false

      self.data = 
        email: ''
        password: ''

      self.fake = 
        check: false
        
      usercookie =
        shortcode:    data.shortcode
        auth_ticket:  data.auth_ticket
        role:         data.role || null
        guest:        false

      $cookies.putObject 'user', usercookie
      
      self.lForm.$setPristine()
      self.lForm.$setUntouched()

      $timeout () -> smoothScr.scroll('#body-top', 0) unless redirect
      root.$emit 'proveSession', redirect
      
    , (response) ->
      self.formProccess  = false
      self.data.password = ''

      self.errorType = 1 if response.data.error == "Access denied"
      self.errorType = 2 if response.data.error == "Password incorrect"
      self.errorType = 3 if response.data.error == "E-Mail not confirmed"      

      UI.notify false, response.data.error #unless self.errorType?