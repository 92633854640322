angular.module('app.controllers').controller('LoginCtrl', ['$scope', '$rootScope', 'APICheckin', '$timeout', '$cookies', 'smoothScr', 'UI'].append(function(self, root, APICheckin, $timeout, $cookies, smoothScr, UI) {
  self.formProccess = false;
  // errorType
  // 1 - wrong email 'Access denied'
  // 2 - wrong pass 'Password incorrect'
  // 3 - not confirmed 'E-Mail not confirmed'
  self.errorType = null;
  self.resetError = function() {
    if (self.errorType == null) {
      return;
    }
    return self.errorType = null;
  };
  self.saveForm = function(form) {
    return self.lForm = form;
  };
  self.data = {
    email: '',
    password: ''
  };
  self.passType = true;
  self.togglePass = function() {
    return self.passType = !self.passType;
  };
  self.fake = {
    check: false
  };
  return self.login = function(redirect = true) {
    var log, ref, ref1;
    if ((ref = root.user) != null ? ref.auth_ticket : void 0) {
      return;
    }
    self.errorType = null;
    self.formProccess = true;
    log = angular.copy(self.data);
    if ((ref1 = log.email) === 'sm@sm') {
      log.email = 'rreyes4211@tconnectionsinc.com';
      log.password = '1234qweRt';
    }
    return (new APICheckin(log)).$save({
      action: 'checkin'
    }, function(data) {
      var usercookie;
      self.formProccess = false;
      self.data = {
        email: '',
        password: ''
      };
      self.fake = {
        check: false
      };
      usercookie = {
        shortcode: data.shortcode,
        auth_ticket: data.auth_ticket,
        role: data.role || null,
        guest: false
      };
      $cookies.putObject('user', usercookie);
      self.lForm.$setPristine();
      self.lForm.$setUntouched();
      $timeout(function() {
        if (!redirect) {
          return smoothScr.scroll('#body-top', 0);
        }
      });
      return root.$emit('proveSession', redirect);
    }, function(response) {
      self.formProccess = false;
      self.data.password = '';
      if (response.data.error === "Access denied") {
        self.errorType = 1;
      }
      if (response.data.error === "Password incorrect") {
        self.errorType = 2;
      }
      if (response.data.error === "E-Mail not confirmed") {
        self.errorType = 3;
      }
      return UI.notify(false, response.data.error); //unless self.errorType?
    });
  };
}));
